var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.showDialog && !_vm.buttonInsideDialog
    ? _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          !_vm.isInsideDialog
            ? _c(
                "v-btn",
                {
                  staticClass: "mr-1",
                  attrs: {
                    icon: _vm.icon,
                    disabled: _vm.disabled,
                    color: "error",
                    loading: _vm.deleting,
                    depressed: "",
                    "e2e-delete-button-confirm": "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.deleteItem()
                    },
                  },
                },
                [
                  _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                    _vm._v("delete"),
                  ]),
                  _vm._v(" " + _vm._s(_vm.buttonName) + " "),
                ],
                1
              )
            : _vm.isInsideDialog
            ? _c(
                "v-btn",
                {
                  attrs: {
                    text: "",
                    disabled: _vm.disabled,
                    color: "error",
                    loading: _vm.deleting,
                    "e2e-delete-button-confirm": "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.deleteItem()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.buttonName) + " ")]
              )
            : _vm._e(),
          !_vm.icon && !_vm.isInsideDialog
            ? _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    outlined: "",
                    exact: "",
                    text: "",
                    to: _vm.cancelRoute,
                    disabled: _vm.deleting,
                  },
                },
                [_vm._v("cancel")]
              )
            : _vm._e(),
        ],
        1
      )
    : !_vm.showDialog && _vm.buttonInsideDialog
    ? _c(
        "v-btn",
        {
          attrs: {
            color: "error",
            icon: _vm.icon,
            disabled: _vm.disabled,
            loading: _vm.deleting,
            text: "",
            "e2e-delete-button-confirm": "",
          },
          on: {
            click: function ($event) {
              return _vm.deleteItem()
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.buttonName) + " ")]
      )
    : _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        class: _vm.block ? "justify-start flex-grow-1" : "",
                        attrs: {
                          block: _vm.block,
                          icon: _vm.icon,
                          disabled: _vm.disabled,
                          color: "secondary",
                          text: "",
                          loading: _vm.deleting,
                        },
                      },
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { left: "" } }, [_vm._v("delete")]),
                      _vm._v(" " + _vm._s(_vm.buttonName) + " "),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.deleteDialog,
            callback: function ($$v) {
              _vm.deleteDialog = $$v
            },
            expression: "deleteDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("v-icon", { staticClass: "mr-1" }, [_vm._v("delete")]),
                  _c("span", { staticClass: "secondary--text" }, [
                    _vm._v(_vm._s(_vm.buttonName)),
                  ]),
                ],
                1
              ),
              _c("v-divider", { staticClass: "mb-1" }),
              _c("v-card-text", [
                _c("div", { domProps: { innerHTML: _vm._s(_vm.warningText) } }),
                _vm.objectType === _vm.nuvolosObjectTypes.TABLE
                  ? _c(
                      "ul",
                      { staticClass: "mt-3" },
                      _vm._l(_vm.selected, function (table) {
                        return _c("li", { key: table.tid }, [
                          _vm._v(_vm._s(table.long_id)),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _vm.objectType === _vm.nuvolosObjectTypes.COLUMN
                  ? _c(
                      "ul",
                      { staticClass: "mt-3" },
                      _vm._l(_vm.selected, function (column) {
                        return _c("li", { key: column.cid }, [
                          _vm._v(_vm._s(column.long_id)),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-1",
                      attrs: { text: "", "e2e-delete-button-cancel": "" },
                      on: {
                        click: function ($event) {
                          _vm.deleteDialog = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        color: "error",
                        "e2e-delete-button-confirm": "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.deleteItem()
                        },
                      },
                    },
                    [_vm._v("Delete")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }